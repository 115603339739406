// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FactTab from "../../pages/ArticleRegulationTool/FactTab.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as Belt_HashMapString from "rescript/lib/es6/belt_HashMapString.js";
import * as RelayRouter__Bindings from "rescript-relay-router/src/RelayRouter__Bindings.mjs";
import * as RelayRouter__Internal__DeclarationsSupport from "rescript-relay-router/src/RelayRouter__Internal__DeclarationsSupport.mjs";

var loadedRouteRenderers = Belt_HashMapString.make(9);

function make(prepareDisposeTimeoutOpt) {
  var prepareDisposeTimeout = prepareDisposeTimeoutOpt !== undefined ? prepareDisposeTimeoutOpt : 300000;
  var match = RelayRouter__Internal__DeclarationsSupport.makePrepareAssets(loadedRouteRenderers, prepareDisposeTimeout);
  var prepareRoute = match.prepareRoute;
  var getPrepared = match.getPrepared;
  var routeName = "Root";
  var loadRouteRenderer = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName, loadedRouteRenderers);
  };
  var makePrepareProps = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            childParams: pathParams
          };
  };
  var routeName$1 = "Root__Home";
  var loadRouteRenderer$1 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__Home_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$1, loadedRouteRenderers);
  };
  var makePrepareProps$1 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$2 = "Root__ArticlesSearch";
  var loadRouteRenderer$2 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticlesSearch_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$2, loadedRouteRenderers);
  };
  var makePrepareProps$2 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            selectedItemId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItemId"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$3 = "Root__ArticleList";
  var loadRouteRenderer$3 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleList_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$3, loadedRouteRenderers);
  };
  var makePrepareProps$3 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$4 = "Root__ArticleFromTypo3";
  var loadRouteRenderer$4 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleFromTypo3_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$4, loadedRouteRenderers);
  };
  var makePrepareProps$4 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"]
          };
  };
  var routeName$5 = "Root__ArticleDetail";
  var loadRouteRenderer$5 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleDetail_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$5, loadedRouteRenderers);
  };
  var makePrepareProps$5 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            tab: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "tab"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            selectedFactId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedFactId"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  var routeName$6 = "Root__ArticleRegulationToolList";
  var loadRouteRenderer$6 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleRegulationToolList_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$6, loadedRouteRenderers);
  };
  var makePrepareProps$6 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location
          };
  };
  var routeName$7 = "Root__ArticleRegulationToolDetail";
  var loadRouteRenderer$7 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__ArticleRegulationToolDetail_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$7, loadedRouteRenderers);
  };
  var makePrepareProps$7 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            id: pathParams["id"],
            selectedFactId: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedFactId"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            tab: Belt_Option.getWithDefault(Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "tab"), (function (value) {
                        return FactTab.parse(decodeURIComponent(value));
                      })), FactTab.defaultValue)
          };
  };
  var routeName$8 = "Root__TicketsSearch";
  var loadRouteRenderer$8 = function () {
    return RelayRouter__Internal__DeclarationsSupport.doLoadRouteRenderer((function () {
                  return import("../Root__TicketsSearch_route_renderer.mjs").then(function (m) {
                              return m.renderer;
                            });
                }), routeName$8, loadedRouteRenderers);
  };
  var makePrepareProps$8 = function (environment, pathParams, queryParams, $$location) {
    return {
            environment: environment,
            location: $$location,
            searchText: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            selectedItem: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItem"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            filterOpen: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "filterOpen"), (function (value) {
                    switch (value) {
                      case "false" :
                          return false;
                      case "true" :
                          return true;
                      default:
                        return ;
                    }
                  })),
            type_: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "type_"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            employee: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "employee"), (function (value) {
                    return decodeURIComponent(value);
                  })),
            created_at: Belt_Option.flatMap(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "created_at"), (function (value) {
                    return decodeURIComponent(value);
                  }))
          };
  };
  return [{
            path: "/",
            name: routeName,
            __$rescriptChunkName__: "Root_route_renderer",
            loadRouteRenderer: loadRouteRenderer,
            preloadCode: (function (environment, pathParams, queryParams, $$location) {
                return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName, loadRouteRenderer, makePrepareProps, environment, pathParams, queryParams, $$location);
              }),
            prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps, (function (pathParams, queryParams) {
                              return "Root:";
                            }), getPrepared, routeName, loadRouteRenderer, intent);
              }),
            children: [
              {
                path: "/",
                name: routeName$1,
                __$rescriptChunkName__: "Root__Home_route_renderer",
                loadRouteRenderer: loadRouteRenderer$1,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$1, loadRouteRenderer$1, makePrepareProps$1, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$1, (function (pathParams, queryParams) {
                                  return "Root__Home:";
                                }), getPrepared, routeName$1, loadRouteRenderer$1, intent);
                  }),
                children: []
              },
              {
                path: "/articles-search",
                name: routeName$2,
                __$rescriptChunkName__: "Root__ArticlesSearch_route_renderer",
                loadRouteRenderer: loadRouteRenderer$2,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$2, loadRouteRenderer$2, makePrepareProps$2, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$2, (function (pathParams, queryParams) {
                                  return "Root__ArticlesSearch:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItemId"), "");
                                }), getPrepared, routeName$2, loadRouteRenderer$2, intent);
                  }),
                children: []
              },
              {
                path: "/articles",
                name: routeName$3,
                __$rescriptChunkName__: "Root__ArticleList_route_renderer",
                loadRouteRenderer: loadRouteRenderer$3,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$3, loadRouteRenderer$3, makePrepareProps$3, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$3, (function (pathParams, queryParams) {
                                  return "Root__ArticleList:";
                                }), getPrepared, routeName$3, loadRouteRenderer$3, intent);
                  }),
                children: []
              },
              {
                path: "/article_from_typo3_id/:id",
                name: routeName$4,
                __$rescriptChunkName__: "Root__ArticleFromTypo3_route_renderer",
                loadRouteRenderer: loadRouteRenderer$4,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$4, loadRouteRenderer$4, makePrepareProps$4, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$4, (function (pathParams, queryParams) {
                                  return "Root__ArticleFromTypo3:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "");
                                }), getPrepared, routeName$4, loadRouteRenderer$4, intent);
                  }),
                children: []
              },
              {
                path: "/articles/:id",
                name: routeName$5,
                __$rescriptChunkName__: "Root__ArticleDetail_route_renderer",
                loadRouteRenderer: loadRouteRenderer$5,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$5, loadRouteRenderer$5, makePrepareProps$5, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$5, (function (pathParams, queryParams) {
                                  return "Root__ArticleDetail:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "tab"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedFactId"), "");
                                }), getPrepared, routeName$5, loadRouteRenderer$5, intent);
                  }),
                children: []
              },
              {
                path: "/article-regulation-tool",
                name: routeName$6,
                __$rescriptChunkName__: "Root__ArticleRegulationToolList_route_renderer",
                loadRouteRenderer: loadRouteRenderer$6,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$6, loadRouteRenderer$6, makePrepareProps$6, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$6, (function (pathParams, queryParams) {
                                  return "Root__ArticleRegulationToolList:";
                                }), getPrepared, routeName$6, loadRouteRenderer$6, intent);
                  }),
                children: []
              },
              {
                path: "/article-regulation-tool/:id",
                name: routeName$7,
                __$rescriptChunkName__: "Root__ArticleRegulationToolDetail_route_renderer",
                loadRouteRenderer: loadRouteRenderer$7,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$7, loadRouteRenderer$7, makePrepareProps$7, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$7, (function (pathParams, queryParams) {
                                  return "Root__ArticleRegulationToolDetail:" + Core__Option.getOr(Js_dict.get(pathParams, "id"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedFactId"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "tab"), "");
                                }), getPrepared, routeName$7, loadRouteRenderer$7, intent);
                  }),
                children: []
              },
              {
                path: "/search",
                name: routeName$8,
                __$rescriptChunkName__: "Root__TicketsSearch_route_renderer",
                loadRouteRenderer: loadRouteRenderer$8,
                preloadCode: (function (environment, pathParams, queryParams, $$location) {
                    return RelayRouter__Internal__DeclarationsSupport.preloadCode(loadedRouteRenderers, routeName$8, loadRouteRenderer$8, makePrepareProps$8, environment, pathParams, queryParams, $$location);
                  }),
                prepare: (function (environment, pathParams, queryParams, $$location, intent) {
                    return prepareRoute(environment, pathParams, queryParams, $$location, makePrepareProps$8, (function (pathParams, queryParams) {
                                  return "Root__TicketsSearch:" + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "searchText"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "selectedItem"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "filterOpen"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "type_"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "employee"), "") + Core__Option.getOr(RelayRouter__Bindings.QueryParams.getParamByKey(queryParams, "created_at"), "");
                                }), getPrepared, routeName$8, loadRouteRenderer$8, intent);
                  }),
                children: []
              }
            ]
          }];
}

export {
  make ,
}
/* loadedRouteRenderers Not a pure module */
